(($) ->
  $slickUsage = $('[data-js-hook="slick-slider-usage"]')
  usageSlickSlider = () =>
    $slickUsage.slick
      slidesToShow: 1
      slidesToScroll: 1
      arrows: false
      dots: true
      centerMode: true
      focusOnSelect: false
      speed: 500
      responsive: true
      adaptiveHeight: true
      customPaging: () ->
        '<a class="SlickSliderIcons__Button"></a>'
    @slickInitialised = true

  @slickInitialised = false


  if $(window).width() <= 768
    usageSlickSlider()

  $(window).resize ->
    if $(window).width() <= 768 && !@slickInitialised
      usageSlickSlider()

    else if @slickInitialised && $(window).width() > 768
      $slickUsage.slick('unslick')
      @slickInitialised = false

  $('body').waitForImages ->
    teletype = (item) ->
      $(item).teletype
        text: [
          'npm'
          'chisel-cms --appId <APP_ID> --serverUrl '
          ' --serverURL <https://HEROKU_APP_NAME.herokuapp.com/parse>'
        ]
        typeDelay: 0
        backDelay: 20
        loop: 1

    # create teletype
    teletype '[data-js-hook="type-text"]'

    #TODO bring to consistency
    $tab = $('a[data-toggle="tab"]')
    $tab.on 'shown.bs.tab', (event) ->
      $tab.removeClass('is--active')
      $(event.target).addClass('is--active')

    $slickDescription = $('[data-js-hook="slick-slider-description"]')
    $slickIcons = $('[data-js-hook="slick-slider-icons"]')
    $slickControl = $('[data-js-hook="slick-control"]')
    $slickArrowRight = $('<img class="Icon Svg Integration__Arrow--right"  src="assets/img/icons/icon-arrow-right.svg" alt="icon arrow right" />')
    $slickArrowLeft = $('<img class="Icon Svg Integration__Arrow--left" src="assets/img/icons/icon-arrow-left.svg" alt="icon arrow left" />')

    $slickDescription.slick
      infinite: true
      slidesToShow: 1
      slidesToScroll: 1
      initialSlide: 1
      autoplay: true
      fade: true
      arrows: false
      dots: true
      centerMode: true
      centerPadding: '1px'
      speed: 500
      responsive: true
      customPaging: () ->
        '<a class="SlickSliderIcons__Button"></a>'
      asNavFor: '[data-js-hook="slick-slider-icons"]'

    $slickIcons.slick
      infinite: true
      initialSlide: 1
      autoplay: true
      focusOnSelect: false
      responsive: true
      arrows: false
      centerMode: true
      centerPadding: '90px'
      slidesToShow: 3
      slideToScroll: 1
      speed: 500
      customPaging: () ->
        '<a class="SlickSliderIcons__Button"></a>'
      responsive: [
        {
          breakpoint: 480
          settings:
            autoplay: true
            arrows: false
            centerMode: true
            centerPadding: '25px'
            slidesToShow: 3
            draggable: false
        }
      ]
      asNavFor: '[data-js-hook="slick-slider-description"]'

    $slickIcons.on 'click', (event) ->
      event.preventDefault()

    $slickIcons.on 'beforeChange', (event, slick, currentSlide, nextSlide, direction) ->
      $currentSlideIcon = $('[data-js-hook="slick-slider-icons"] .slick-slide[data-slick-index="' + nextSlide + '"] svg')
      $currentSlideIcon.before($slickArrowRight).after($slickArrowLeft)

      if nextSlide == 0
        $slickIcons.slick('slickGoTo', 0)
      else if nextSlide > 2
        $slickIcons.slick('slickGoTo', 0)

    # Tooltip
    $tooltip = $('[data-toggle="tooltip"]')
    $tooltip.tooltip()

    $feature = $('[data-js-hook="feature"]')

    $feature.on 'mouseover', (event) ->
      $(event.target).find($tooltip).addClass('is--active')

    $feature.on 'mouseleave', (event) ->
      $tooltip.removeClass('is--active')

    $titleInput = $('[data-js-hook="title-input"]')
    $categoryInput = $('[data-js-hook="category-input"]')
    $contentInput = $('[data-js-hook="content-input"]')

    if $(window).width() <= 768
      $titleJson = $('.slick-slide:not(.slick-cloned) [data-js-hook="title-json"] > span')
      $categoryJson = $('.slick-slide:not(.slick-cloned) [data-js-hook="category-json"] > span')
      $contentJson = $('.slick-slide:not(.slick-cloned) [data-js-hook="content-json"] > span')
    else
      $titleJson = $('[data-js-hook="title-json"] > span')
      $categoryJson = $('[data-js-hook="category-json"] > span')
      $contentJson = $('[data-js-hook="content-json"] > span')

    $titleOutput = $('[data-js-hook="title-output"]')
    $categoryOutput = $('[data-js-hook="category-output"]')
    $contentOutput = $('[data-js-hook="content-output"]')

    initJsonTitleValue = $titleJson.text()
    initJsonCategoryValue = $categoryJson.text()
    initJsonContentValue = $contentJson.text()

    $('.Form__Input').on 'focusin', (event) ->
      event.stopPropagation()

      $(@).parent().addClass('is--focused')

      $titleInput.keyup(->
        value = $(this).val()
        $titleJson.text value if value.length > 0
      ).keyup()

      $categoryInput.keyup(->
        value = $(this).val()
        $categoryJson.text value if value.length > 0
      ).keyup()

      $contentInput.keyup(->
        value = $(this).val()
        $contentJson.text value if value.length > 0
      ).keyup()

    $('.Form__Input').on 'blur', (event) ->
      event.stopPropagation()

      $(@).parent().removeClass('is--focused')

    $('[data-js-hook="post-publish"]').on 'click', (event) ->
      event.preventDefault()

      $titleOutput.text($titleInput.val()) if $titleInput.val() != ''
      $categoryOutput.text($categoryInput.val()) if $categoryInput.val() != ''
      $contentOutput.text($contentInput.val()) if $contentInput.val() != ''

      if $(window).width() <= 768
        $slickUsage.slick('slickGoTo', 2)

    # change all images to svg's
    addLoadEvent = (func) ->
      oldonload = window.onload
      if typeof window.onload != 'function'
        window.onload = func
      else
        window.onload = ->
          oldonload()
          func()

    addLoadEvent $('img.Svg').svgToInline()

    $('[data-toggle="popover"]').on 'click', (event) ->
      console.log "#{$(this).attr('id')}"
      $item = $('[rel=popover]')
      $item.popover(
        placement: 'bottom'
        title: ' <a class="close" href="#">&times;</a>'
        trigger: 'click'
        html: true
        template: '#' + $(this).attr('id') + ' '
        container: 'body'
      ).on 'shown.bs.popover', (event) ->

        # 'aria-describedby' is the id of the current popover
        current_popover = '#' + $(event.target).attr('aria-describedby')
        $cur_pop = $(current_popover)

        $cur_pop.find('[data-js-hook="popover-close"]').click ->
          $pop.popover 'hide'
      $item

    # setTimeout (->
    #   createPopover = (item, title) ->
    #     $pop = $(item)
    #     $pop.popover(
    #       placement: 'bottom'
    #       title: (title or '&nbsp;') + ' <a class="close" href="#">&times;</a>'
    #       trigger: 'click'
    #       html: true
    #       template: ' ' + template + ' '
    #       container: 'body'
    #     ).on 'shown.bs.popover', (event) ->
    #
    #       # 'aria-describedby' is the id of the current popover
    #       current_popover = '#' + $(event.target).attr('aria-describedby')
    #       $cur_pop = $(current_popover)
    #
    #       $cur_pop.find('[data-js-hook="popover-close"]').click ->
    #         $pop.popover 'hide'
    #     $pop
    #
    #   # create popover
    #   template = $('#popover').html()
    #   createPopover '[rel=popover]'
    # ), 300

    searchClient = algoliasearch('9W6H5UJAZ9', 'b271e454132ef47d6c156c1b1accd15e')
    search = instantsearch(
      indexName: 'prod_vulcan-docs'
      searchClient: searchClient)
    search.addWidget instantsearch.widgets.searchBox(container: '#searchBox')
    search.addWidget instantsearch.widgets.hits(
      container: '#hits'
      templates: {
          empty: '',
          item: "<a class='Item__Link' href='{{Slug}}.html'><h4 class='Item__Title'>{{Title}}</h4></a>"
        }
      )
    search.start()

    $('#SearchForm').click ->
      $('#searchModal').modal 'toggle'
      return


) jQuery
